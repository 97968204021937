@import '../../../assets/scss/utils';

.db-loaderSmall {
  &__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--dashboard-block-bg);
  }
}
