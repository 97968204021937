@font-face {
    font-family: "db-icons-font";
    src: url("db-icons-font.woff?445791b6ccf4744e6befb6500ae1622d") format("woff");
}

span[class^="icon-"]:before, span[class*=" icon-"]:before {
    font-family: db-icons-font !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-circle:before {
    content: "\f101";
}
.icon-add:before {
    content: "\f102";
}
.icon-alert:before {
    content: "\f103";
}
.icon-analytics:before {
    content: "\f104";
}
.icon-arrow-left:before {
    content: "\f105";
}
.icon-chevron-down:before {
    content: "\f106";
}
.icon-chevron-left:before {
    content: "\f107";
}
.icon-chevron-right:before {
    content: "\f108";
}
.icon-chevron-up:before {
    content: "\f109";
}
.icon-close:before {
    content: "\f10a";
}
.icon-config:before {
    content: "\f10b";
}
.icon-cross:before {
    content: "\f10c";
}
.icon-dashboard:before {
    content: "\f10d";
}
.icon-dots:before {
    content: "\f10e";
}
.icon-edit:before {
    content: "\f10f";
}
.icon-error-connection:before {
    content: "\f110";
}
.icon-error-file:before {
    content: "\f111";
}
.icon-expand:before {
    content: "\f112";
}
.icon-filter:before {
    content: "\f113";
}
.icon-home:before {
    content: "\f114";
}
.icon-info:before {
    content: "\f115";
}
.icon-news:before {
    content: "\f116";
}
.icon-on-off:before {
    content: "\f117";
}
.icon-order:before {
    content: "\f118";
}
.icon-permissions-balea:before {
    content: "\f119";
}
.icon-permissions-user:before {
    content: "\f11a";
}
.icon-plane:before {
    content: "\f11b";
}
.icon-reload:before {
    content: "\f11c";
}
.icon-rss:before {
    content: "\f11d";
}
.icon-search:before {
    content: "\f11e";
}
.icon-share:before {
    content: "\f11f";
}
.icon-telefonica-logo:before {
    content: "\f120";
}
.icon-tick:before {
    content: "\f121";
}
.icon-trash:before {
    content: "\f122";
}
.icon-user:before {
    content: "\f123";
}
.icon-users:before {
    content: "\f124";
}
.icon-warning:before {
    content: "\f125";
}
