@import '../../../../assets/scss/utils';

.treemap-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  > div {
    width: 100%;
    height: 100%;
  }
}
