@import '../../../assets/scss/utils.scss';

.metric-route {
  padding: 0 sizer(2) sizer(1) sizer(2);
  display: flex;
  flex-direction: column;
  gap: sizer(2);
  flex: 2 1;
  box-sizing: border-box;
  font-size: fs(large);
  overflow-x: auto;

  &__description {
    color: var(--fc-secondary);
  }

  &__link {
    text-decoration: inherit;
    color: var(--button-primary-text-color);

    &:hover {
      color: var(--button-primary-text-color-hover);
    }
  }

  &__info {
    display: flex;
    gap: sizer(1.25);

    .db-icon {
      &:hover {
        color: var(--c-default-icon);
      }
    }
  }
}
