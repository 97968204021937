body {
  /*Grays Palette*/
  --c-black: #000;
  --c-black-1: #111;
  --c-black-1-rgb: 17, 17, 17;
  --c-gray-1: #e2e2e2;
  --c-gray-2: #606269;
  --c-gray-3: #91949b;
  --c-white: #fff;
  --c-white-rgb: 255, 255, 255;
  /*Suite Palette*/
  --c-arca: #59c2c9;
  --c-arca-light: #6aa8a4;
  --c-journey: #e66c64;
  --c-journey-light: #d5a394;
  --c-arena: #eac344;
  --c-data-campus: #0166ff;
  /*System Palette*/
  --c-error: #ff4545;
  --c-error-rgb: 255, 69, 69;
  --c-success: #01b172;
  --c-warning: #ee9423;
  --c-info: #747c85;

  &.theme-dark {
    /* Font colors */
    --fc-base: var(--c-white);
    --fc-secondary: #8d969f;
    --fc-error: var(--c-error);

    /*Primary Palette*/
    --main-bg: #1c1b21;
    --main-bg-rgb: 28, 27, 33;
    --main-bg-65: rgba(var(--main-bg-rgb), 0.65);
    --secondary-bg: #292930;
    --cards-bg: #747c85;
    --cards-bg-rgb: 116, 124, 133;
    --cards-bg-rgb-10: rgba(var(--cards-bg-rgb), 0.1);
    --cards-alt-bg: #323640;
    --body-primary: #ffffff;
    --body-secondary: #8d969f;
    --body-secondary-rgb: 141, 150, 159;
    --body-tertiary: #9d84a1;
    --dropdown-bg: #4e5361;

    /*Secondary Palette*/
    --c-accent-1: #2c4a79;
    --c-accent-2: #0166ff;
    --c-accent-2-rgb: 1, 102, 255;
    --c-accent-2-light: #4b9eff;
    --c-accent-2-25: rgba(var(--c-accent-2-rgb), 0.25);
    --c-accent-2-dark: #005ae3;
    --c-accent-3: #1fc5d0;
    --c-accent-4: #188bb4;
    --c-accent-4-rgb: 24, 139, 180;
    --c-accent-5: #99c45d;
    --c-accent-6: #ffae34;
    --c-accent-7: #c07348;
    --c-accent-8: #755e52;
    --c-divider: rgba(var(--c-white-rgb), 0.08);
    --c-divider-mediumlight: rgba(var(--c-white-rgb), 0.2);
    --c-divider-light: rgba(var(--c-white-rgb), 0.16);
    --c-divider-xlight: rgba(var(--c-white-rgb), 0.05);
    --c-divider-xxlight: rgba(var(--c-white-rgb), 0.03);
    --c-departures: #3ea2ff;
    --c-arrivals: #4fc393;

    /* Complementary/UI colors */
    --c-default-icon: #747c85;
    --c-default-icon-hover: var(--c-white);

    /*Main header*/
    --main-header--bg: var(--main-bg);
    --home-wrapper-bg: var(--main-bg);

    /*Dashboard*/
    --dashboard-wrapper-bg: var(--main-bg);
    --dashboard-block-bg: var(--secondary-bg);
    --dashboard-block-form-actions-border-top: var(--secondary-bg);

    /*Dashboard paginator*/
    --dashboard-paginator-font-color: var(--c-white);

    /*Dashboard form*/
    --form-section-title-color: var(--c-accent-2-light);
    --form-column-icon-color: var(--body-secondary);

    /*Dashboard Block*/
    --dashboard-block-header-title-color: var(--c-white);

    /*Dasboard general row values component*/
    --dashboard-generalRowValues-label-color: var(--fc-secondary);
    --dashboard-generalRowValues-label-date-color: var(--c-white);
    --dashboard-generalRowValues-url-color: var(--c-accent-2-light);
    --dashboard-generalRowValues-item-separator-color: var(--c-divider-light);

    /*Dashboard table reports component*/
    --dashboard-tableReports-item-bg: var(--c-divider-xlight);
    --dashboard-tableReports-item-color: var(--c-white);
    --dashboard-tableReports-url-color: var(--c-accent-2-light);
    --dashboard-tableReports-header-text-color: var(--fc-secondary);

    /*Dashboard featured route component*/
    --dashboard-featured-route-title-color: var(--fc-secondary);
    --dashboard-featured-route-details-color: var(--c-accent-2-light);
    --dashboard-featured-route-icons-color: var(--fc-secondary);

    /*Side panel*/
    --side-panel-bg: var(--secondary-bg);
    --side-panel-actions-border: var(--c-divider-light);

    /*Side panel charts list*/
    --side-panel-charts-list-title-color: var(--c-white);
    --side-panel-charts-list-item-title-color: var(--c-white);
    --side-panel-charts-list-item-bg-hover: var(--c-accent-2-25);
    --side-panel-charts-list-item-description-color: var(--fc-secondary);

    /*Navbar*/
    --navbar-bg: var(--secondary-bg);

    /*Notifications*/
    --notification-bg: var(--secondary-bg);

    /*Input group*/
    --input-group-label-color: var(--fc-secondary);
    --input-group-label-color-disabled: rgba(var(--c-white-rgb), 0.3);
    --input-group-error-container-bc: var(--c-error);
    --input-group-error-font-color: var(--c-error);

    /*Input text*/
    --input-text-font-color: var(--c-white);
    --input-text-placeholder-font-color: var(--fc-secondary);
    --input-text-border-bottom-color: var(--body-secondary);
    --input-text-border-bottom-color-hover: var(--c-white);
    --input-text-main-color-disabled: rgba(var(--c-white-rgb), 0.3);
    --input-text-rounded-bg: var(--c-divider-xlight);


    /*Input number*/
    --input-number-font-color: var(--c-white);
    --input-number-placeholder-font-color: var(--fc-secondary);
    --input-number-border-bottom-color: var(--body-secondary);
    --input-number-border-bottom-color-hover: var(--c-white);
    --input-number-main-color-disabled: rgba(var(--c-white-rgb), 0.3);

    /*Input select*/
    --input-select-font-color: var(--fc-base);
    --input-select-font-color-hover: var(--fc-base);
    --input-select-font-color-focused: var(--fc-base);
    --input-select-indicator-color: var(--fc-secondary);
    --input-select-indicator-color-hover: var(--fc-base);
    --input-select-indicator-color-focused: var(--fc-base);
    --input-select-border-bottom-color: var(--body-secondary);
    --input-select-border-bottom-color-hover: var(--c-white);
    --input-select-border-bottom-color-focused: var(--c-white);
    --input-select-placeholder-color: var(--fc-secondary);
    --input-select-disabled-color: rgba(var(--c-white-rgb), 0.3);
    --input-select-menu-bg: var(--dropdown-bg);
    --input-select-option-hover-bg: rgba(var(--c-white-rgb), 0.3);
    --input-select-option-selected-bg: var(--c-accent-2);

    /*Input select multiple*/
    --input-select-multiple-value-font-color: var(--c-white);
    --input-select-multiple-value-font-bg: var(--dropdown-bg);

    /*Input check*/
    --input-check-bg: transparent;
    --input-check-bg-checked: var(--c-white);
    --input-check-bg-checked-hover: grey;
    --input-check-bg-checked-disabled: rgba(var(--body-secondary-rgb), 0.5);
    --input-check-border-color: var(--body-secondary);
    --input-check-border-color-hover: var(--c-white);
    --input-check-border-color-disabled: rgba(var(--body-secondary-rgb), 0.5);
    --input-check-tick-color: var(--c-black-1);
    --input-check-tick-color-disabled: var(--body-secondary);
    --input-check-text-color: var(--c-white);

    /*Input radio*/
    --input-radio-bg: transparent;
    --input-radio-bg-checked: var(--c-white);
    --input-radio-bg-checked-hover: grey;
    --input-radio-bg-checked-disabled: rgba(var(--body-secondary-rgb), 0.5);
    --input-radio-border-color: var(--body-secondary);
    --input-radio-border-color-hover: var(--c-white);
    --input-radio-border-color-disabled: rgba(var(--body-secondary-rgb), 0.5);
    --input-radio-tick-color: var(--c-black-1);
    --input-radio-tick-color-disabled: var(--body-secondary);
    --input-radio-text-color: var(--c-white);

    /*Dropdown menu*/
    --dropdown-menu-list-bg: var(--dropdown-bg);
    --dropdown-menu-list-text-color: var(--c-white);
    --dropdown-menu-list-item-hover-bg: rgba(var(--c-white-rgb), 0.3);
    --dropdown-menu-list-item-hover-icon-color: var(--c-white);

    /*Button*/
    --button-primary-bg-color: var(--c-white);
    --button-primary-bg-color-hover: var(--c-accent-2-light);
    --button-primary-bg-color-active: var(--c-accent-2);
    --button-primary-bg-color-disabled: var(--c-gray-2);
    --button-primary-text-color: var(--c-accent-2);
    --button-primary-text-color-hover: var(--c-white);
    --button-primary-text-color-active: var(--c-white);
    --button-primary-text-color-disabled: rgba(var(--c-white-rgb), 0.3);

    --button-secondary-bg-color: var(--c-white);
    --button-secondary-bg-color-hover: var(--c-gray-1);
    --button-secondary-bg-color-active: var(--c-gray-3);
    --button-secondary-bg-color-disabled: var(--c-gray-2);
    --button-secondary-text-color: var(--c-black-1);
    --button-secondary-text-color-hover: var(--c-black-1);
    --button-secondary-text-color-active: var(--c-black-1);
    --button-secondary-text-color-disabled: rgba(var(--c-white-rgb), 0.3);

    --button-bordered-bg-color: transparent;
    --button-bordered-bg-color-hover: transparent;
    --button-bordered-bg-color-active: transparent;
    --button-bordered-bg-color-disabled: transparent;
    --button-bordered-border-color: var(--c-white);
    --button-bordered-border-color-hover: var(--c-accent-2-light);
    --button-bordered-border-color-active: var(--c-accent-2);
    --button-bordered-border-color-disabled: var(--c-gray-2);
    --button-bordered-text-color: var(--c-white);
    --button-bordered-text-color-hover: var(--c-accent-2-light);
    --button-bordered-text-color-active: var(--c-accent-2);
    --button-bordered-text-color-disabled: var(--c-gray-2);

    --button-simple-bg-color: transparent;
    --button-simple-bg-color-hover: transparent;
    --button-simple-bg-color-active: transparent;
    --button-simple-bg-color-disabled: transparent;
    --button-simple-text-color: var(--c-accent-2-light);
    --button-simple-text-color-hover: var(--c-white);
    --button-simple-text-color-active: var(--c-accent-2);
    --button-simple-text-color-disabled: rgba(var(--c-white-rgb), 0.3);

    --button-simple-danger-bg-color: transparent;
    --button-simple-danger-bg-color-hover: transparent;
    --button-simple-danger-bg-color-active: transparent;
    --button-simple-danger-bg-color-disabled: transparent;
    --button-simple-danger-text-color: var(--c-error);
    --button-simple-danger-text-color-hover: var(--c-white);
    --button-simple-danger-text-color-active: var(--c-accent-2);
    --button-simple-danger-text-color-disabled: rgba(var(--c-error-rgb), 0.3);

    --button-icon-bg-color: transparent;
    --button-icon-bg-color-hover: transparent;
    --button-icon-bg-color-active: transparent;
    --button-icon-bg-color-disabled: transparent;
    --button-icon-text-color: var(--c-white);
    --button-icon-text-color-hover: var(--c-white);
    --button-icon-text-color-active: var(--c-accent-2);
    --button-icon-text-color-disabled: rgba(var(--c-white-rgb), 0.3);

    --button-icon-icon-color: var(--c-accent-2-light);
    --button-icon-icon-color-hover: var(--c-white);
    --button-icon-icon-color-active: var(--c-accent-2);
    --button-icon-icon-color-disabled: rgba(var(--c-white-rgb), 0.3);

    

    /*Charts*/
    --charts-general-text-color: var(--fc-secondary);

    /*Charts tooltip*/
    --charts-tooltip-background: var(--c-white);
    --charts-tooltip-font-color: var(--c-black-1);

    /*Chart map*/
    --chart-map-title-color: var(--c-white);
    --chart-map-description-color: var(--fc-secondary);
    --chart-map-detail-url-color: var(--c-accent-2-light);

    /*My profile*/
    --my-profile-bg: var(--secondary-bg);
    --my-profile-title-font-color: var(--c-white);
    --my-profile-section-bg: var(--c-divider-xlight);
    --my-profile-primary-font-color: var(--c-white);
    --my-profile-secondary-font-color: var(--fc-secondary);
    --my-profile-font-connected: var(--c-success);
    --my-profile-section-divider: var(--c-divider-light);
    --my-profile-table-roles-row-bg: var(--c-divider-xlight);

    /*Rss panel*/
    --rss-panel-bg: var(--secondary-bg);
    --rss-panel-section-bg: var(--c-divider-xlight);
    --rss-panel-title-font-color: var(--c-white);

    /*Modal*/
    --modal-overlay-bg: var(--main-bg-65);
    --modal-dialog-bg: var(--cards-alt-bg);
    --modal-dialog-font-color: var(--c-white);

    /*Loader*/
    --loader-description-font-color: var(--c-info);

    /*Notifications page*/
    --notifications-page-title-color: var(--c-white);
    --notifications-page-alert-ball-border: var(--dashboard-wrapper-bg);
    --notifications-page-carousel-title-color: var(--c-white);
    --notifications-page-carousel-item-main-font-color: var(--c-white);
    --notifications-page-carousel-navigation-chevron-color: var(--fc-secondary);
    --notifications-page-carousel-navigation-chevron-color-hover: var(--c-white);

    /*Balea section*/
    --balea-section-tabs-main-color: var(--c-data-campus);
    --balea-section-tabs-active-color: var(--c-white);
    --balea-section-header-search-fc: rgba(var(--c-white-rgb), 0.4);
    --balea-section-header-search-bc: var(--fc-secondary);
    --balea-section-header-links-fc: var(--fc-secondary);
    --balea-section-header-button-bg: var(--c-white);
    --balea-section-header-button-hover-bg: var(--c-data-campus);
    --balea-section-header-button-fc: var(--c-data-campus);
    --balea-section-header-button-hover-fc: var(--c-white);
    --balea-section-header-links-hover-fc: var(--c-white);
    --balea-section-header-links-active-fc: var(--c-white);
    --balea-section-header-links-active-border-color: var(--c-data-campus);
    --balea-section-card-bg: var(--cards-bg-rgb-10);
    --balea-section-header-card-title-fc: var(--c-white);
    --balea-section-body-card-fc: var(--c-white);
    --balea-section-breadcrumb-icon-fc: var(--fc-secondary);
    --balea-section-breadcrumb-fc: var(--c-white);
    --balea-section-header-container-fc: var(--c-white);
    --balea-section-header-bullet-color: var(--fc-secondary);
    --balea-section-item-selected-bg: var(--cards-bg-rgb-10);
    --balea-section-item-selected-separator-bg: var(--c-divider-light);
    --balea-section-item-selected-title-fc: var(--c-white);
    --balea-section-item-selected-description-fc: var(--c-white);
    --balea-section-km-table-header-fc: var(--fc-secondary);
    --balea-section-km-table-row-border-color: var(--main-bg);
    --balea-section-km-table-item-bg: var(--cards-bg-rgb-10);
    --balea-section-km-table-item-name-fc: var(--c-white);
    --balea-section-km-table-is-detail-border-color: var(--c-white);
    --balea-section-km-taglist-content-bg: transparent;
    --balea-section-km-taglist-border-color: rgba(var(--c-white-rgb), 0.25);
    --balea-section-km-taglist-fc: var(--c-accent-2-light);
    --balea-section-km-status-tag-enabled-color: var(--c-success);
    --balea-section-modal-bg: var(--secondary-bg);
    --balea-section-modal-title-fc: var(--c-white);
    --balea-section-modal-main-fc: var(--fc-secondary);
    --balea-section-input-text-bg: transparent;
    --balea-section-inputs-border-color: var(--fc-secondary);
    --balea-section-input-file-bg: transparent;
    --balea-section-input-file-hover-border-color: var(--c-data-campus);
    --balea-section-input-file-hover-button-fc: var(--c-white);
    --balea-section-textarea-bg: transparent;
    --balea-section-button-secondary-bg: transparent;
    --balea-section-button-secondary-fc: var(--c-data-campus);
    --balea-section-avatar-application-bg: var(--c-data-campus);
  }

  // App selector
  --app-selector-header-font-color: var(--body-secondary);
  --app-selector-body-row-bg: rgba(var(--c-white-rgb), 0.05);
  --app-selector-body-row-bg-hover: rgba(var(--c-white-rgb), 0.1);
  --app-selector-body-row-img-border: var(--c-black);
  --app-selector-body-row-hover-bg: var(--c-accent-2-25);
  --app-selector-titles-color: var(--c-white);

  //App selected
  --app-selected-info-separator-bg: var(--c-divider-light);
  --app-selected-info-font-color: var(--fc-secondary);
  --app-selected-info-font-color-hide: var(--c-accent-2-light);
  --app-selected-users-list-header-font-color: var(--fc-secondary);
  --app-selected-users-list-row-bg-color: var(--c-divider-xlight);
  --app-selected-users-list-row-font-color: var(--c-white);
  --app-selected-users-list-row-disabled-bg: var(--c-divider-xxlight);
  --app-selected-users-list-row-disabled-font-color: var(--c-divider-mediumlight);

  //Toggle component
  --app-toggle-rest-bg: transparent;
  --app-toggle-ball-rest-bg: var(--body-secondary);
  --app-toggle-ball-active-bg: var(--c-white);
  --app-toggle-rest-border-color: var(--fc-secondary);
  --app-toggle-active-border-color: transparent;

  //Notification panel
  --notification-panel-title-color: var(--fc-base);
  --notification-panel-item-main-text-color: var(--fc-base);
  --notification-panel-item-secondary-text-color: var(--fc-secondary);
  --notification-panel-item-detail-text-color: var(--c-accent-2-light);
  --notification-panel-item-bg: var(--cards-bg-rgb-10);
  --notification-panel-item-separate-border-color: var(--c-divider-light);

  //Shared layout
  --shared-layout-container-bg: var(--main-bg);
  --shared-layout-container-border-color: var(--secondary-bg);
  --shared-layout-container-text-color: var(--c-white);
  --shared-layout-container-text-navigation-color: var(--c-accent-2-light);
}
