@import '../../../assets/scss/utils';

.db-icon {
  align-items: center;
  display: inline-flex;
  font-family: $icons-font-family !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    display: block;
    line-height: initial;
  }
}
