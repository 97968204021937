@import '../../../../assets/scss/utils';

.alphanumeric-chart {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__value {
    color: var(--fc-base);
    font-weight: bold;
    font-size: fs('xxxxlarge');
    border-radius: 10px;
  }
}
