@import '../../../assets/scss/utils.scss';

.chart {
  background: var(--dashboard-block-bg);
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    position: relative;
    display: flex;
    padding: sizer(3) sizer(2) sizer(2) sizer(2);
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid;

    &.arcaweb {
      border-top-color: var(--c-arca);
    }

    &.arena {
      border-top-color: var(--c-arena);
    }

    &.journey {
      border-top-color: var(--c-journey);
    }

    &.datacampus {
      border-top-color: var(--c-data-campus);
    }

    .title {
      display: flex;
      align-items: center;
      max-width: calc(100% - #{sizer(4)} * 2);

      > span {
        display: block;
        font-size: fs(regular);
        font-family: $ff-base-bold;

        &.separator {
          margin: 0 sizer(1);
        }
      }

      .source {
        text-transform: uppercase;

        &.arcaweb {
          color: var(--c-arca);
        }

        &.arena {
          color: var(--c-arena);
        }

        &.journey {
          color: var(--c-journey);
        }

        &.datacampus {
          color: var(--c-data-campus);
        }
      }
    }

    .db-icon {
      cursor: pointer;
      display: none;
      position: absolute;
      right: sizer(2);
      top: sizer(2.5);
    }
  }

  &__data {
    flex: 1;
  }

  &:hover {
    .db-icon {
      display: block;
    }
  }
}
