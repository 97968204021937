@import '../../../assets/scss/utils';

.my-profile {
  &__wrapper-hero {
    height: 100%;
    left: sizer(8);
    position: fixed;
    top: 0;
    z-index: z('super');
  }

  &__wrapper {
    overflow: hidden;
    height: 100%;
  }

  &__wrapper-in {
    display: flex;
    flex-flow: column;
    background-color: var(--my-profile-bg);
    width: sizer(80);
    height: 100%;
    margin: 0 sizer(5) 0 0;
    box-shadow: 0px 0px sizer(5) rgba(17, 17, 17, 0.75);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 sizer(4);
    height: sizer(9);
    background-color: var(--my-profile-section-bg);
    gap: sizer(3);

    .db-icon {
      font-size: fs(regular);
      cursor: pointer;
    }
  }

  &__title {
    font-size: fs(xlarge);
    color: var(--my-profile-title-font-color);
    font-weight: 600;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: sizer(4);

    .db-input-select {
      width: sizer(20);
      margin-top: sizer(3);
    }
  }

  &__body {
    flex-grow: 1;
    overflow-y: auto;
    height: 0;
  }

  &__section {
    padding: sizer(2.5) sizer(4) sizer(3) sizer(4);
    margin-bottom: sizer(0.25);
    background-color: var(--my-profile-section-bg);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-title {
    font-size: fs(regular);
    color: var(--my-profile-primary-font-color);
    margin-bottom: sizer(2.5);
    font-weight: 700;
  }

  &__section-row {
    display: flex;
  }

  &__stats-item {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: 0 sizer(2);
    border-right: 1px solid var(--my-profile-section-divider);

    > p {
      margin-bottom: sizer(1.5);
      font-weight: 700;

      &:first-child {
        font-size: fs(small);
        color: var(--my-profile-secondary-font-color);
        font-weight: 400;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  &__user-info {
    display: flex;
  }

  &__item {
    display: flex;
    flex-flow: column;
    margin-right: sizer(3);
    flex-grow: 1;

    p,
    span {
      font-size: fs(small);
    }

    &.first {
      > span {
        color: var(--my-profile-primary-font-color);
        margin-bottom: sizer(1);
        font-weight: 700;
      }
    }

    &.data-highlight {
      flex-grow: 1;

      > p {
        margin-bottom: sizer(1);
        color: var(--my-profile-secondary-font-color);
        > span {
          color: var(--my-profile-primary-font-color);
          margin-bottom: sizer(1);
        }

        &.connected {
          color: var(--my-profile-font-connected);
        }
      }
    }
  }

  &__personal-data {
    & > p {
      font-size: fs(small);
      color: var(--my-profile-primary-font-color);
      margin-bottom: sizer(1);
      font-weight: 700;

      & > span {
        font-size: fs(small);
        color: var(--my-profile-primary-font-color);
        margin-bottom: sizer(1);
        font-weight: 500;
      }
    }
  }

  &__table-roles {
    width: 100%;

    th {
      text-align: left;

      &:first-child {
        padding-left: sizer(2.5);
      }
    }

    tr {
      height: sizer(6);
      line-height: sizer(6);

      td {
        &:first-child {
          padding-left: sizer(2.5);
        }
      }
    }

    tbody {
      tr {
        background-color: var(--my-profile-table-roles-row-bg) !important;

        & .table-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .highlight {
      height: rem(2px);
      background-color: transparent;
    }
  }
}
