@import '@assets/scss/utils';

.dropdown-menu-component {
  position: relative;
}

.dropdown-menu {
  &__container {
    position: absolute;
    bottom: sizer(-12.5);
    left: rem(-93px);
    z-index: z(default);
  }

  &__list {
    padding: sizer(1) 0 sizer(1) 0;
    background-color: var(--dropdown-menu-list-bg);
  }

  &__list-item {
    display: flex;
    height: sizer(5);
    align-items: center;
    width: 100%;
    padding: 0 sizer(2.5);
    cursor: pointer;

    .db-icon {
      margin-right: sizer(1.5);
    }

    &:hover {
      background-color: var(--dropdown-menu-list-item-hover-bg);

      .db-icon {
        color: var(--dropdown-menu-list-item-hover-icon-color);
      }
    }
  }

  &__list-item-text {
    font-size: fs(regular);
    color: var(--dropdown-menu-list-text-color);
  }
}
