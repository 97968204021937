@import '../../../assets/scss/utils';

.db-button {
  display: inline-flex;
  align-items: center;
  height: sizer(4);
  text-transform: uppercase;
  font-size: fs(msmall);
  padding: 0 sizer(1.5);
  font-family: $ff-base;
  font-weight: $fw-bold;
  border: 1px solid transparent;

  &:last-child {
    margin-right: 0;
  }

  &.primary {
    background-color: var(--button-primary-bg-color);
    color: var(--button-primary-text-color);

    &:hover,
    &:focus {
      background-color: var(--button-primary-bg-color-hover);
      color: var(--button-primary-text-color-hover);
    }

    &:active {
      background-color: var(--button-primary-bg-color-active);
      color: var(--button-primary-text-color-active);
    }

    &:disabled {
      background-color: var(--button-primary-bg-color-disabled);
      color: var(--button-primary-text-color-disabled);
    }
  }

  &.secondary {
    background-color: var(--button-secondary-bg-color);
    color: var(--button-secondary-text-color);

    &:hover,
    &:focus {
      background-color: var(--button-secondary-bg-color-hover);
      color: var(--button-secondary-text-color-hover);
    }

    &:active {
      background-color: var(--button-secondary-bg-color-active);
      color: var(--button-secondary-text-color-active);
    }

    &:disabled {
      background-color: var(--button-secondary-bg-color-disabled);
      color: var(--button-secondary-text-color-disabled);
    }
  }

  &.bordered {
    background-color: var(--button-bordered-bg-color);
    color: var(--button-bordered-text-color);
    border-color: var(--button-bordered-border-color);

    &:hover,
    &:focus {
      background-color: var(--button-bordered-bg-color-hover);
      color: var(--button-bordered-text-color-hover);
      border-color: var(--button-bordered-border-color-hover);
    }

    &:active {
      background-color: var(--button-bordered-bg-color-active);
      color: var(--button-bordered-text-color-active);
      border-color: var(--button-bordered-border-color-active);
    }

    &:disabled {
      background-color: var(--button-bordered-bg-color-disabled);
      color: var(--button-bordered-text-color-disabled);
      border-color: var(--button-bordered-border-color-disabled);
    }
  }

  &.simple {
    background-color: var(--button-simple-bg-color);
    color: var(--button-simple-text-color);
    padding: 0;

    &:hover,
    &:focus {
      background-color: var(--button-simple-bg-color-hover);
      color: var(--button-simple-text-color-hover);
    }

    &:active {
      background-color: var(--button-simple-bg-color-active);
      color: var(--button-simple-text-color-active);
    }

    &:disabled {
      background-color: var(--button-simple-bg-color-disabled);
      color: var(--button-simple-text-color-disabled);
    }
  }

  &.simple-danger {
    background-color: var(--button-simple-danger-bg-color);
    color: var(--button-simple-danger-text-color);
    padding: 0;

    &:hover,
    &:focus {
      background-color: var(--button-simple-danger-bg-color-hover);
      color: var(--button-simple-danger-text-color-hover);
    }

    &:active {
      background-color: var(--button-simple-danger-bg-color-active);
      color: var(--button-simple-danger-text-color-active);
    }

    &:disabled {
      background-color: var(--button-simple-danger-bg-color-disabled);
      color: var(--button-simple-danger-text-color-disabled);
    }
  }

  &.withIcon {
    background-color: var(--button-icon-bg-color);
    color: var(--button-icon-text-color);
    font-size: fs(regular);
    text-transform: none;
    font-weight: $fw-regular;
    padding: 0;

    .db-icon {
      margin-right: sizer(1.25);
      color: var(--button-icon-icon-color);

      &:hover {
        color: var(--c-default-icon);
      }
    }

    &:hover,
    &:focus {
      background-color: var(--button-icon-bg-color-hover);
      color: var(--button-icon-text-color-hover);

      .db-icon {
        color: var(--button-icon-icon-color-hover);
      }
    }

    &:active {
      background-color: var(--button-icon-bg-color-active);
      color: var(--button-icon-text-color-active);

      .db-icon {
        color: var(--button-icon-icon-color-active);
      }
    }

    &:disabled {
      background-color: var(--button-icon-bg-color-disabled);
      color: var(--button-icon-text-color-disabled);

      .db-icon {
        color: var(--button-icon-icon-color-disabled);
      }
    }
  }
}
