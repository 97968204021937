@use 'sass:math';

@each $name, $value in $size-vars {
  .fs-#{$name} {
    font-size: $value;
    line-height: $lh-base;
  }
}

@for $i from 0 through 20 {
  $count: ($i * 5) + 5;

  .width-#{$count} {
    width: percentage(math.div($count, 100));
  }
}
