@import '../../../assets/scss/utils';

$source-height: 20px;
$icon-size: 40px;

.carousel-item {
  display: flex;
  flex-direction: column;
  gap: sizer(1);
  width: 98%;

  &__header {
    height: sizer(4);
    padding: sizer(2);
    padding-bottom: 0;
    font-size: fs(regular);
    color: var(--notifications-page-carousel-title-color);
    text-transform: uppercase;
    font-weight: 700;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    padding: sizer(2);
    gap: sizer(2);

    .medium-wrapper {
      display: flex;
      gap: sizer(2);
    }

    .description-wrapper {
      display: flex;
      flex-direction: column;
      gap: sizer(1);
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .news-icon {
      height: $icon-size;
      width: $icon-size;
    }
  }

  &__footer {
    height: $source-height;
    padding-left: sizer(2);

    &.medium {
      padding-left: sizer(9);
    }

    .source {
      font-size: fs(msmall);
      color: var(--notifications-page-carousel-item-main-font-color);
      font-style: italic;
      height: $source-height;
    }
  }

  &__date {
    font-size: fs(msmall);
    color: var(--notifications-page-carousel-item-main-font-color);
    padding-top: sizer(1);
  }

  &__title {
    font-weight: 700;
    color: var(--notifications-page-carousel-item-main-font-color);
    display: -webkit-box;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    &.small {
      -webkit-line-clamp: 1;
    }
  }

  &__description {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    &.small {
      -webkit-line-clamp: 3;
    }

    &.medium {
      -webkit-line-clamp: 8;
    }

    &.large {
      -webkit-line-clamp: 10;
    }
  }
}
