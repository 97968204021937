/* Z-index */
$z-layers: (
  modal-content: 9003,
  modal-overlay: 9002,
  super: 9000,
  above: 900,
  default: 1,
  below: -1,
);

/* Font-size */
$size-vars: (
  xsmall: 12px,
  msmall: 13px,
  small: 14px,
  regular: 15px,
  base: 16px,
  large: 18px,
  xlarge: 20px,
  xxlarge: 24px,
  xxlargeplus: 28px,
  xxxlarge: 30px,
  xxxxlarge: 40px,
);
/* Breakpoints */
$breakpoints: (
  lg: 1366px,
  md: 1280px,
  sm: 1024px,
  xs: 768px,
);
/* Spacing */
$gutter: 8px;
/* Fonts */
$ff-base: 'Telefonica Web Regular', Arial, sans-serif;
$ff-base-bold: 'Telefonica Web Bold', Arial, sans-serif;
$ff-base-extra-bold: 'Telefonica Web Extra Bold', Arial, sans-serif;
$ff-base-light: 'Telefonica Web Light', Arial, sans-serif;
$ff-base-italic: 'Telefonica Cap Italic', Arial, sans-serif;
$icons-font-family: 'db icon';
$fw-regular: normal;
$fw-bold: bold;
$fw-light: 300;
$lh-base: 1.31em;
$lh-small: 1.14em;
/* Layout */
