@import '../../assets/scss/utils';

.db-input-group {
  &__label {
    display: block;
    font-size: fs(small);
    color: var(--input-group-label-color);

    &.is-disabled {
      color: var(--input-group-label-color-disabled);
    }
  }

  &__label-error {
    color: var(--input-group-error-font-color);
    font-size: fs(small);
  }

  &__error-wrapper {
    min-height: sizer(3.5);
    width: 100%;
  }

  &__error-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top: 1px solid;
    border-top-color: var(--input-group-error-container-bc);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;

    .db-icon {
      font-size: fs(msmall);
      color: var(--input-group-error-font-color);
      margin-right: sizer(1);
    }
  }
}
