@import '../../../assets/scss/utils';

.globalChartLabel {
  text-align: center;
  width: 100%;
  height: sizer(5);
}

.global-chart-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--fc-base);

  > div {
    height: 100%;
  }
}
