@import '../../assets/scss/utils';
.block {
  &__container {
    height: 100%;

    &:hover {
      .block {
        &__icons {
          display: flex;
        }
      }
    }
  }

  &__icons {
    display: none;
    position: absolute;
    right: sizer(2);
    top: sizer(2.5);

    > .db-icon {
      margin-right: sizer(1);
    }
  }

  &__header {
    position: relative;
    display: flex;
    padding: sizer(3) sizer(2) sizer(2) sizer(2);
    justify-content: space-between;
    border-top: 1px solid;

    &.arca {
      border-top-color: var(--c-arca);
    }

    &.arena {
      border-top-color: var(--c-arena);
    }

    &.journey {
      border-top-color: var(--c-journey);
    }

    &.datacampus {
      border-top-color: var(--c-data-campus);
    }

    .db-icon {
      cursor: pointer;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    max-width: calc(100% - #{sizer(4)} * 2);

    > span {
      display: block;
      font-size: fs(regular);
      font-family: $ff-base-bold;

      &.separator {
        margin: 0 sizer(1);
      }
    }
  }

  &__source-title {
    text-transform: uppercase;

    &.arca {
      color: var(--c-arca);
    }

    &.arena {
      color: var(--c-arena);
    }

    &.journey {
      color: var(--c-journey);
    }

    &.datacampus {
      color: var(--c-data-campus);
    }
  }

  &__title {
    color: var(--dashboard-block-header-title-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__body {
    height: calc(100% - 69px);
    position: relative;

    .chart-filters-wrapper {
      height: sizer(5);
      &.dropdown-wrapper {
        > label {
          flex-grow: 1;
          .db-input-select {
            width: 20%;
          }
        }
      }
      .k-chart {
        padding-left: rem(3px);
      }
    }
  }
}
