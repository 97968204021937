@import '../../../assets/scss/utils';

.rss-panel {
  &__wrapper-hero {
    height: 100%;
    left: sizer(8);
    position: fixed;
    top: 0;
    z-index: z('super');
  }

  &__wrapper {
    overflow: hidden;
    height: 100%;
  }

  &__wrapper-in {
    display: flex;
    flex-flow: column;
    background-color: var(--rss-panel-bg);
    width: sizer(80);
    height: 100%;
    margin: 0 sizer(5) 0 0;
    box-shadow: 0px 0px sizer(5) rgba(17, 17, 17, 0.75);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 sizer(4);
    height: sizer(9);
    background-color: var(--rss-panel-section-bg);

    .db-icon {
      font-size: fs(regular);
      cursor: pointer;
    }
  }

  &__body {
    height: calc(100% - #{sizer(9)});
    padding-top: sizer(2);
  }

  &__title {
    font-size: fs(xlarge);
    color: var(--rss-panel-title-font-color);
    font-weight: 600;
  }
}
