@import '../../../assets/scss/utils';

.db-carousel {
  margin-bottom: sizer(7);

  .alice-carousel {
    &__stage {
      > li {
        .carousel-item {
          padding-right: sizer(3);
        }
      }
    }

    &__prev-btn,
    &__next-btn {
      position: absolute;
      width: 60px;
      top: -50px;
      padding: 0;

      &-item {
        padding: 0;
        color: var(--notifications-page-carousel-navigation-chevron-color);
        font-size: fs(xxxlarge);

        &:hover {
          color: var(--notifications-page-carousel-navigation-chevron-color-hover);
        }
      }
    }

    &__prev-btn {
      right: 100px;
    }

    &__next-btn {
      right: 10px;
    }
  }

  &.large {
    .alice-carousel {
      &__stage {
        > li {
          .carousel-item {
            height: 432px;
          }
        }
      }
    }
  }

  &.medium {
    .alice-carousel {
      &__stage {
        > li {
          .carousel-item {
            height: 304px;
          }
        }
      }
    }
  }

  &.small {
    .alice-carousel {
      &__stage {
        > li {
          .carousel-item {
            height: 204px;

            &__description {
              font-size: fs(regular);
              line-height: sizer(3);
            }
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: sizer(3.5);

    > .db-icon {
      font-size: fs(xxlarge);
      cursor: pointer;
    }
  }

  &__title {
    font-weight: 600;
    margin-right: sizer(2.5);
    font-size: fs(xlarge);
    color: var(--notifications-page-carousel-title-color);
  }
}
