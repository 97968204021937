@import '../../../assets/scss/utils.scss';

.metric-widget {
  padding: 0 sizer(2) sizer(1) sizer(2);
  display: flex;
  flex-direction: column;
  gap: sizer(2);
  flex: 1;
  box-sizing: border-box;
  font-size: fs(large);

  &__value {
    font-size: fs(xxxlarge);
    font-weight: 900;

    &--urgent {
      color: var(--c-error);
    }
  }

  &__description {
    color: var(--fc-secondary);
  }

  &__link {
    text-decoration: inherit;
    color: var(--button-primary-text-color);

    &:hover {
      color: var(--button-primary-text-color-hover);
    }
  }
}
