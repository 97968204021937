@import '../../assets/scss/utils';

.news {
  &__body {
    padding: sizer(3) sizer(7) 0 sizer(7);
  }

  &__actions-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: sizer(4);

    &--container {
      display: flex;
      align-items: center;
      position: relative;

      > .db-icon {
        font-size: fs(xxlarge);
        cursor: pointer;
      }

      > .alert-ball {
        position: absolute;
        top: 0;
        left: rem(13px);
        width: rem(13px);
        height: rem(13px);
        background-color: var(--c-error);
        border: 3px solid var(--notifications-page-alert-ball-border);
        border-radius: 50%;
      }
    }
  }

  &__title {
    font-size: fs(xxxlarge);
    margin-right: sizer(3);
    color: var(--notifications-page-title-color);
  }
}
