html {
  height: 100%;
  font-size: 100%;
  text-size-adjust: none;
}

body {
  margin: 0;
  height: 100%;
  line-height: $lh-base;
  font-family: $ff-base;
  color: var(--fc-base);
}
pre {
  font-family: $ff-base;
}

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c7c7cb;
  }
}
