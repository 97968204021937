@import '../../../assets/scss/utils';

.k-dialog-wrapper,
.k-overlay {
  width: 100%;
  height: 100%;
}

.db-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z(default);

  .k-overlay {
    position: absolute;
    background-color: var(--modal-overlay-bg);
  }

  .k-dialog {
    position: absolute;
    display: flex;
    flex-flow: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: sizer(177.5);
    height: sizer(90);
    padding: sizer(4);
    background-color: var(--modal-dialog-bg);
    box-shadow: 0px 20px 80px 0px #111111bf;

    .k-dialog-content {
      display: flex;
      flex-grow: 1;
      flex-flow: column;
      overflow: hidden;
      align-items: center;
      gap: sizer(2);
    }

    .k-dialog-buttongroup {
      display: flex;
      justify-content: flex-end;

      .db-button {
        &.primary {
          margin-left: sizer(4);
        }
      }
    }
  }

  .db-modal-dialog {
    &__header {
      padding: sizer(2) sizer(2) sizer(2) sizer(2);
      margin-bottom: sizer(4);

      .db-modal-title {
        width: 100%;
        font-size: 2rem;
      }

      .db-icon {
        font-size: fs(base);
        cursor: pointer;
        position: absolute;
        top: sizer(5);
      }
    }
  }

  &__icon-wrapper {
    width: sizer(10);
    height: sizer(10);
  }

  &__icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &__title {
    font-weight: 600;
    color: var(--modal-dialog-font-color);
    font-size: fs(xlarge);
    margin-top: sizer(2);
    margin-bottom: sizer(3);
  }

  &__body {
    width: 100%;
    flex-grow: 1;
  }
}
