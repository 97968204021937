@import '../../../assets/scss/utils';

.dashboard-table-reports {
  display: flex;
  flex-flow: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: sizer(1.5);

    span {
      font-family: $ff-base-bold;
      color: var(--dashboard-tableReports-header-text-color) !important;
    }

    > span {
      width: sizer(13);
      font-size: fs(regular);
    }
  }

  &__body {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__container {
    display: flex;
    flex-grow: 1;
    align-items: center;

    > span {
      display: block;
      text-align: center;
      width: 50%;
      font-size: fs(regular);
      color: var(--dashboard-tableReports-item-color);

      &:first-child {
        padding-left: sizer(2.5);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: sizer(13.75);
    background-color: var(--dashboard-tableReports-item-bg);
    margin-bottom: sizer(0.25);

    > a {
      width: sizer(13);
      font-size: fs(regular);
      text-decoration: none;
      color: var(--dashboard-tableReports-url-color);

      &:visited {
        color: var(--dashboard-tableReports-url-color);
        text-decoration: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
