@import '../../../assets/scss/utils';

.navbar {
  background-color: var(--navbar-bg);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: sizer(8);
  z-index: z(super);

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &__list {
    width: 100%;

    &.profile {
      padding-top: sizer(2.5);
    }
  }

  &__item {
    align-items: center;
    display: flex;
    height: sizer(8);
    justify-content: center;
    width: 100%;

    .db-icon {
      cursor: pointer;
    }

    &.analytics {
      .db-icon {
        font-size: fs(xxxlarge);
      }
    }
  }
}
