.db-side-panel {
  display: flex;
  flex-flow: column;
  height: 100%;
  right: 0;
  position: fixed;
  top: 0;
  width: sizer(62.5);
  background-color: var(--side-panel-bg);
  z-index: 10;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sizer(4) sizer(4) sizer(5.5) sizer(4);

    .db-icon {
      font-size: fs(regular);
      cursor: pointer;
    }
  }

  &__title {
    font-size: fs(xlarge);
    font-weight: 600;
  }

  &__subtitle {
    font-size: fs(regular);
    font-weight: 100;
    color: var(--fc-secondary);
  }

  &__body {
    flex-grow: 1;
    overflow-y: auto;
  }

  &.secondary {
    right: sizer(62.5);
  }
  &.tertiary {
    right: sizer(125);
  }
}
