.db-form {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: calc(100% - #{sizer(12)});

  &__structure {
    flex-grow: 1;
    padding: 0 sizer(4);
    overflow-y: auto;
  }

  &__section-title {
    display: block;
    font-family: $ff-base-bold;
    font-size: fs(regular);
    width: 100%;
    color: var(--form-section-title-color);
    border-bottom: 2px solid var(--form-section-title-color);
    padding-bottom: sizer(1);
    margin-bottom: sizer(3);
  }

  &__actions {
    display: flex;
    padding: 0 sizer(4);
    align-items: center;
    justify-content: flex-end;
    height: sizer(10);
    border-top: 1px solid;
    border-top-color: var(--side-panel-actions-border);

    button {
      margin-left: sizer(3);

      &.simple-danger {
        margin-right: auto;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__row {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: sizer(3);

    .switch {
      margin-top: sizer(2);
    }

    > .with-icon {
      display: flex;

      .db-icon {
        color: var(--form-column-icon-color);
        margin-right: sizer(1);

        &:hover {
          color: var(--form-column-icon-color);
        }
      }

      > label {
        width: calc(100% - #{sizer(4)});
      }
    }
  }

  &__col-0 {
    grid-column: 1 / 4;
  }

  &__col-1 {
    grid-column: 1;
  }

  &__col-2 {
    grid-column: 2;
  }

  &__col-3 {
    grid-column: 3;
  }

  .col-big-left {
    grid-column: 1 / 3;
  }

  .col-big-right {
    grid-column: 2 / 4;
  }
}
