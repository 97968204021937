@import '../../../assets/scss/utils';

.k-dialog-wrapper,
.k-overlay {
  width: 100%;
  height: 100%;
}

.k-overlay {
  position: relative;
  background-color: var(--modal-overlay-bg);
}

.k-dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: sizer(77.5);
  height: sizer(42);
  box-shadow: 0px 20px 80px 0px #111111bf;

  .k-dialog-content {
    display: flex;
    padding: sizer(4);
    justify-content: space-around;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    background-color: var(--modal-dialog-bg);
  }

  .k-dialog-actions {
    display: flex;
    justify-content: flex-end;
    background-color: var(--modal-dialog-bg);
    padding: sizer(2);

    .db-button {
      flex: 0;
      white-space: nowrap;

      &.primary {
        margin-left: sizer(4);
      }
    }
  }
}
.db-modal-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z(super);

  &__header {
    height: sizer(4);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: var(--modal-dialog-font-color);
    font-weight: 600;

    .db-icon {
      font-size: fs(base);
      cursor: pointer;
      position: absolute;
    }
  }
  &__icon-wrapper {
    width: sizer(10);
    height: sizer(12);
  }

  &__icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    &.delete {
      background-image: url('../../../assets/images/modal-delete.svg');
    }
  }

  &__title {
    font-weight: 600;
    color: var(--modal-dialog-font-color);
    font-size: fs(xlarge);
    margin-top: sizer(2);
    margin-bottom: sizer(3);
  }

  &__description {
    font-size: fs(regular);
    color: var(--modal-dialog-font-color);
    text-align: center;
    line-height: 26px;
  }
}
