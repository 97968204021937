@import '../../../assets/scss/utils';

.alerts-notifications {
  &__wrapper-hero {
    height: 100%;
    left: sizer(8);
    position: fixed;
    top: 0;
    z-index: z('super');
  }

  &__wrapper {
    overflow: hidden;
    height: 100%;
  }

  &__wrapper-in {
    display: flex;
    flex-flow: column;
    background-color: var(--my-profile-bg);
    width: sizer(80);
    height: 100%;
    margin: 0 sizer(5) 0 0;
    box-shadow: 0px 0px sizer(5) rgba(17, 17, 17, 0.75);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 sizer(4);
    height: sizer(9);
    background-color: var(--my-profile-section-bg);

    .db-icon {
      font-size: fs(regular);
      cursor: pointer;
    }
  }

  &__body {
    height: calc(100% - 130px);
  }

  &__title {
    font-size: fs(xlarge);
    color: var(--my-profile-title-font-color);
    font-weight: 600;
  }

  &__content {
    height: 100%;
    overflow: auto;
  }

  &__tabs {
    display: flex;
    margin-bottom: sizer(2);
  }

  &__tab {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    flex-grow: 1;
    text-align: center;
    padding: sizer(1) sizer(2) sizer(1) sizer(2);
    background: var(--my-profile-section-bg);
    cursor: pointer;

    &:hover {
      background-color: var(--my-profile-bg);
    }

    &.selected {
      background-color: var(--my-profile-bg);
      border-top: 1px white solid;
      cursor: auto;
    }
  }
}
