@import '../../../assets/scss/utils';

.db-input-radio {
  display: block;
  position: relative;
  padding-left: sizer(4);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__text {
    font-size: fs(regular);
    color: var(--input-radio-text-color);
  }
}

.db-input-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.db-input-radio__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: sizer(2.5);
  width: sizer(2.5);
  border: 1px solid;
  border-color: var(--input-check-border-color);
  background-color: var(--input-check-bg);
  border-radius: 50%;
}

.db-input-radio:hover input ~ .db-input-radio__checkmark {
  border-color: var(--input-radio-border-color-hover);
}

.db-input-radio input:checked ~ .db-input-radio__checkmark {
  background-color: var(--input-radio-bg-checked);
  border-color: transparent;
}

.db-input-radio input:checked:hover ~ .db-input-radio__checkmark {
  background-color: var(--input-radio-bg-checked-hover);
  border-color: transparent;
}

.db-input-radio input:disabled ~ .db-input-radio__checkmark {
  border-color: var(--input-radio-border-color-disabled);
}

.db-input-radio input:checked:disabled ~ .db-input-radio__checkmark {
  background-color: var(--input-radio-bg-checked-disabled);
  border-color: transparent;
}

.db-input-radio__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.db-input-radio input:checked ~ .db-input-radio__checkmark:after {
  display: block;
}

.db-input-radio .db-input-radio__checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--input-radio-tick-color);
}

.db-input-radio input:checked:disabled ~ .db-input-radio__checkmark:after {
  background: var(--input-radio-tick-color-disabled);
}
