@import '../../../assets/scss/utils';

.user-selected {
  display: block;
  width: 100%;

  &__row {
    display: flex;
    width: 100%;
    background-color: var(--app-selector-body-row-bg);
    margin-bottom: sizer(4);
  }

  &__cell {
    display: flex;
    height: sizer(10);
    align-items: center;

    &:first-child {
      display: flex;
      padding-left: sizer(2);
      align-items: center;

      p {
        display: block;
        font-size: fs(xlarge);
        font-weight: 600;
      }
    }

    &:last-child {
      flex-grow: 1;
      padding-right: sizer(2);

      p {
        text-align: left;
        font-size: fs(regular);
        color: var(--c-accent-2-light);
      }
    }
  }

  &__cell-img-container {
    width: sizer(6);
    height: sizer(6);
    border-radius: 50%;
    margin-right: sizer(2);
  }

  &__cell-img-uploaded {
    width: sizer(6);
    height: sizer(6);
    border-radius: 50%;
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
  }

  &__cell-img {
    width: sizer(6);
    height: sizer(6);
    border-radius: 50%;
    background-image: url('../../../assets/images/user-default.svg');
    background-size: 100% 100%;
  }

  &__cell-text {
    font-size: fs(regular);
  }

  &__separator {
    width: 1px;
    height: 100%;
    background-color: var(--app-selected-info-separator-bg);
    margin: 0 sizer(5);
  }

  &__info-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: sizer(4);
  }

  &__info {
    display: flex;
    align-items: center;
    height: sizer(5);
    border-radius: sizer(2.5);
    padding: 0 sizer(2);
    background-color: var(--app-selector-body-row-bg);

    .db-icon {
      font-size: fs(xlarge);
      margin-right: sizer(1.25);

      &:hover {
        color: var(--app-selected-info-font-color);
      }
    }

    > p {
      font-size: fs(regular);
      color: var(--app-selected-info-font-color);
    }
  }

  &__info-separator {
    width: 1px;
    height: 100%;
    background-color: var(--app-selected-info-separator-bg);
    margin: 0 sizer(1.5);
  }

  &__info-hide {
    color: var(--app-selected-info-font-color-hide) !important;
    cursor: pointer;
  }

  &__list {
    display: flex;
    width: 100%;
    flex-flow: column;
  }

  &__list-header {
    display: flex;
    align-items: center;
    padding-bottom: sizer(2);
  }

  &__list-header-cell {
    font-weight: 700;
    color: var(--app-selected-users-list-header-font-color);
    font-size: fs(small);

    &.description {
      flex-grow: 1;
      padding-right: 1rem;
    }

    &:first-child {
      width: sizer(43.75);
      flex-grow: inherit;
      padding-left: sizer(2);
    }

    &:last-child {
      width: sizer(25);
      flex-grow: inherit;
    }
  }

  &__list-row {
    display: flex;
    margin-bottom: 2px;
    background-color: var(--app-selected-users-list-row-bg-color);

    &.disabled {
      cursor: auto;
      background-color: var(--app-selected-users-list-row-disabled-bg);
      color: var(--app-selected-users-list-row-disabled-font-color);

      .user-selected__users-list {
        &-cell {
          color: var(--app-selected-users-list-row-disabled-font-color);
        }
      }

      &:hover {
        .user-selected__users-list {
          &-cell {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__list-row-cell {
    min-height: sizer(5);
    padding: sizer(2) 0;

    &.description {
      flex-grow: 1;
      padding-right: sizer(2);
      width: calc(100% - 520px);
      display: flex;
      align-items: center;
    }

    &:first-child {
      width: sizer(43.75);
      flex-grow: inherit;
      padding-left: sizer(2);
    }

    &:last-child {
      width: sizer(25);
      flex-grow: inherit;

      .db-input-group__error-wrapper {
        display: none;
      }
    }
  }
}
