@import '../../../assets/scss/utils';

$dashboard-button-height: rem(32px);
$dashboard-button-fs: '12px';
$dashboard-button-color: #31c5a4;

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &.save {
    background-color: $dashboard-button-color;
    cursor: pointer;
    text-transform: uppercase;
    height: $dashboard-button-height;
    font-size: $dashboard-button-fs;
    padding: 0 sizer(2);
    min-width: 0;
    letter-spacing: 0.6px;
    border: 0;
    font-family: $ff-base-bold;
  }

  &.cancel {
    cursor: pointer;
    text-transform: uppercase;
    height: $dashboard-button-height;
    font-size: $dashboard-button-fs;
    padding: 0 sizer(2);
    min-width: 0;
    letter-spacing: 0.6px;
    border: 0;
    font-family: $ff-base-bold;
    color: $dashboard-button-color;
  }

  &:disabled {
    &:hover {
      cursor: auto;
    }
  }
}