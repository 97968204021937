@import '../../../../assets/scss/utils';

.filters-panel {
  &__tabs {
    display: flex;
    gap: sizer(2);
    margin-bottom: sizer(2);
    padding: 0 sizer(2);
  }

  &__tab {
    font-weight: 700;
    color: var(--fc-secondary);
    font-size: fs(regular);
    border-bottom: 3px solid transparent;
    padding-bottom: sizer(0.5);
    cursor: pointer;

    &:hover {
      color: var(--c-white);
    }

    &.selected {
      border-color: var(--c-accent-2);
      color: var(--c-white);

      &:hover {
        color: var(--c-white);
        cursor: auto;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 0 sizer(2);
    gap: sizer(2);

    & > * {
      flex: 1 1 0;
    }
  }

  &__search {
    position: relative;
    width: 100%;
    padding: 0 sizer(2);

    .db-icon {
      position: absolute;
      top: sizer(1);
      left: sizer(3);
    }
  }
}
