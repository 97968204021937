@import '../../assets/scss/utils';

.dashboard {
  &__actions-row {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-bottom: sizer(2.5);

    .db-input-select {
      width: sizer(40);

      &__control {
        border-bottom: 0 !important;
      }

      &__single-value {
        font-family: $ff-base-bold;
        font-size: fs(xlarge);
      }
    }

    .db-input-group__error-wrapper {
      display: none;
    }

    &--left {
      display: flex;

      .db-icon {
        cursor: pointer;
      }

      .icon-config {
        margin: 0 sizer(3.5);
      }
    }
  }

  &__page-selector {
    position: absolute;
    display: flex;
    align-items: center;
    height: sizer(2.5);
    left: calc(50% - 60px);

    &--number {
      color: var(--dashboard-paginator-font-color);
      font-size: fs(small);
      margin: 0 sizer(2);
    }

    .db-icon {
      cursor: pointer;
    }
  }

  &__title {
    font-size: 2rem;
  }

  &__block {
    background: var(--dashboard-block-bg);
  }

  &__actions {
    .db-button {
      margin-left: sizer(2.5);
      &:first-child {
        margin-left: 0;
      }
    }

    .db-icon {
      font-size: fs(base);
    }
  }

  &__body {
    padding: sizer(3) sizer(7) 0 sizer(7);
    height: 100%;
  }

  &--info {
    position: relative;
    z-index: z(default);
  }

  &__empty {
    border: solid 1px;
    padding: sizer(5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: sizer(3);
    cursor: pointer;

    .title {
      display: block;
      font-size: fs(xxxlarge);
      color: var(--shared-layout-container-text-color);
    }
  }

  &__empty-wrapper {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
