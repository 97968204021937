@import '../../../assets/scss/utils';

.dashboard-featured-route {
  padding-left: sizer(2);

  &__title {
    display: block;
    font-size: fs(small);
    color: var(--dashboard-featured-route-title-color);
    margin-bottom: sizer(0.5);
  }

  &__view-detail {
    display: block;
    font-size: fs(msmall);
    margin-bottom: sizer(2);
    text-decoration: none;
    color: var(--dashboard-featured-route-details-color);
    cursor: pointer;

    &:visited {
      color: var(--dashboard-featured-route-details-color);
      text-decoration: none;
    }
  }

  &__info {
    display: flex;

    .db-icon {
      color: var(--dashboard-featured-route-icons-color);
      font-size: fs(xlarge);

      &:hover {
        color: var(--dashboard-featured-route-icons-color);
      }
    }

    > span {
      margin-right: sizer(1);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__info-value {
    display: flex;
    font-family: $ff-base-bold;
    font-size: fs(base);
  }
}
