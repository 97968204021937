@import '../../../assets/scss/utils';

.db-side-panel {
  &__chart-list {
    margin-bottom: sizer(2);

    .db-form__section-title {
      margin-bottom: 0;
      margin-left: sizer(4);
      margin-right: sizer(4);
      width: calc(100% - 64px);
    }

    &--title {
      display: block;
      font-size: fs(xlarge);
      color: var(--side-panel-charts-list-title-color);
      padding-left: sizer(4);
      margin-bottom: sizer(1);
    }

    &--item {
      display: flex;
      cursor: pointer;
      padding: sizer(2) sizer(4) sizer(2) sizer(4);

      &:hover {
        background-color: var(--side-panel-charts-list-item-bg-hover);
      }

      &-title {
        display: block;
        font-size: fs(xlarge);
        color: var(--side-panel-charts-list-item-title-color);
        margin-bottom: sizer(1);
      }

      &-description {
        font-size: fs(small);
        color: var(--side-panel-charts-list-item-description-color);
      }
    }

    &--item-info {
      padding-left: sizer(2);
    }

    &--item-preview {
      display: flex;
      cursor: pointer;
      min-width: sizer(16);
      width: sizer(16);
      height: sizer(12);
      background-color: white;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.bars {
        background-image: url('../../../assets/images/bars.svg');
      }

      &.bars-percentage {
        background-image: url('../../../assets/images/bars-percentage.svg');
      }

      &.donut {
        background-image: url('../../../assets/images/donut.svg');
      }

      &.general-row {
        background-image: url('../../../assets/images/general-row.svg');
      }

      &.map {
        background-image: url('../../../assets/images/map.svg');
      }

      &.sparkline {
        background-image: url('../../../assets/images/sparkline.svg');
      }

      &.table {
        background-image: url('../../../assets/images/table.svg');
      }

      &.featured-route {
        background-image: url('../../../assets/images/featured-route.svg');
      }

      &.vertical-bars {
        background-image: url('../../../assets/images/vertical-bars.svg');
      }

      &.single-values {
        background-image: url(../../../assets//images/single-values.svg);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
