@import '../../../assets/scss/utils';

.__react_component_tooltip {
  max-width: 25rem;
  overflow-wrap: break-word;
  white-space: normal;
  line-height: 2rem;
}

.db-tooltip-text{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
