@import '../../assets/scss/utils';

@keyframes appear {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.home {
  min-height: 100%;
  padding: 0 sizer(6) sizer(1.5) sizer(6);
  width: 100%;
  background-color: var(--home-wrapper-bg);

  &__header {
    margin-bottom: sizer(3);
    h1 {
      font-size: fs(xxxlarge);
      font-weight: $fw-regular;
      margin-bottom: sizer(5);
    }

    p {
      font-size: fs(regular);
      max-width: sizer(77);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: sizer(2);

    .app {
      animation-name: appear;
      animation-timing-function: ease-out;
      animation-duration: 0.5s;
      opacity: 0;
      animation-fill-mode: forwards;
      height: sizer(25);
      display: flex;
      gap: sizer(3);

      &__card {
        flex-basis: sizer(48);
        background-color: var(--app-color);
        display: flex;
        flex-direction: column;
        position: relative;
        padding: sizer(3);
        overflow: hidden;
      }

      &__title {
        font-size: fs(xxxlarge);
        text-transform: uppercase;
      }

      &__image {
        width: 40%;
        align-self: flex-end;
      }

      &__link {
        position: absolute;
        bottom: sizer(3);
        left: sizer(3);
        background-color: var(--button-secondary-bg-color);
        padding: sizer(1);
        color: var(--c-black);
        text-decoration: inherit;
        font-weight: bold;
        text-transform: uppercase;
      }

      &__metrics {
        position: relative;
        flex: 1;
        min-width: 0;
        border-top: 1px solid var(--app-color);
        background-color: #ffffff0a;
        display: flex;
        flex-direction: column;

        .metrics-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: sizer(3);

          &__title {
            font-size: fs(xlarge);

            &--highlight {
              color: var(--app-color);
            }
          }

          &__date-info {
            display: flex;
            align-items: center;
            gap: sizer(2);
          }
        }

        .metrics-wrapper {
          flex: 1;
          display: flex;
          max-width: 100%;
          scroll-behavior: smooth;

          & > * {
            border-right: 1px solid var(--body-secondary);
            &:last-child {
              border-color: transparent;
            }
          }
        }
      }

      &--datacampus {
        --app-color: var(--c-data-campus);
      }

      &--arcaweb {
        --app-color: var(--c-arca);
      }

      &--arena {
        --app-color: var(--c-arena);
      }

      &--journey {
        --app-color: var(--c-journey);
      }
    }
  }
}
