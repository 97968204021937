@import '../../../assets/scss/utils';

.db-app-selector {
  .db-input-group__label,
  .db-input-text,
  .db-input-group__error-wrapper {
    max-width: sizer(36);
  }

  &__container {
    width: 100%;
  }

  &__header {
    display: flex;
    padding-bottom: sizer(2);
  }

  &__header-cell {
    font-family: $ff-base-bold;
    font-size: fs(small);
    color: var(--app-selector-header-font-color);
    text-align: center;

    &:first-child {
      text-align: left;
      min-width: sizer(30);
      width: sizer(30);
      padding-left: sizer(2);
    }

    &:last-child {
      flex-grow: 1;
      padding-right: sizer(2);
    }
  }

  &__body {
    display: block;
    width: 100%;
  }

  &__body-row {
    display: flex;
    width: 100%;
    background-color: var(--app-selector-body-row-bg);
    margin-bottom: rem(2px);

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      cursor: pointer;

      .db-app-selector {
        &__body-cell {
          background-color: var(--app-selector-body-row-hover-bg);
        }
      }
    }
  }

  &__body-cell {
    display: flex;
    height: sizer(9);

    &:first-child {
      display: flex;
      min-width: sizer(30);
      width: sizer(30);
      padding-left: sizer(2);
      align-items: center;

      p {
        display: block;
        align-self: flex-start;
        padding-top: sizer(2);
      }
    }

    &:last-child {
      flex-grow: 1;
      padding-right: sizer(2);

      p {
        text-align: left;
        line-height: rem(22px);
        padding-top: sizer(2);
      }
    }
  }

  &__body-cell-img-container {
    width: sizer(6);
    height: sizer(6);
    margin-right: sizer(2);
  }

  &__body-cell-img {
    width: sizer(6);
    height: sizer(6);
    background-image: url('../../../assets/images/app-default.svg');
    background-size: auto sizer(5);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin: sizer(1);
  }

  &__body-cell-img-uploaded {
    width: sizer(6);
    height: sizer(6);
    border-radius: sizer(2);
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
  }

  &__body-cell-text {
    font-size: fs(regular);
  }
}
