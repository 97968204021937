@import './assets/scss/utils';

$header-height: 88px;

#root {
  height: 100%;

  > div {
    height: 100%;
  }
}

.main {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: sizer(4.5) sizer(7) sizer(3) sizer(14);
    background-color: var(--main-header--bg);
    height: $header-height;

    .db-input-select {
      margin-top: sizer(3.5);

      &__control {
        border-width: 1px !important;
        border: 1px solid;
        border-color: var(--input-select-border-bottom-color);
        border-radius: sizer(2.5) !important;
        padding: 0 0 0 sizer(1);

        &::before {
          content: '';
          width: sizer(3.75);
          height: sizer(3.75);
          margin-right: sizer(1);
          background-image: url('/assets/images/world.svg');
        }

        &:hover {
          border-color: var(--input-select-border-bottom-color-hover);
        }
      }
    }

    &--tools {
      display: flex;
      align-items: center;
      gap: sizer(4);

      .prize {
        display: flex;
        align-items: center;
        gap: sizer(0.5);

        .icon {
          display: inline-flex;
          font-size: fs(xxlarge);

          &.icon-chevron-up {
            color: var(--c-success);
          }
          &.icon-chevron-down {
            color: var(--c-error);
          }
        }
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-flow: column;
  min-height: calc(100% - #{$header-height});
  padding-left: sizer(8);
  width: 100%;
  background-color: var(--dashboard-wrapper-bg);
}
