@import '../../../../assets/scss/utils';

.donut-container {
  width: 100%;
  height: 100%;

  .legend-container {
    display: flex;
    width: 100%;
    justify-content: center;

    &__item {
      display: flex;
      margin-right: sizer(1.5);
      align-items: center;
      border-radius: 2px;
    }

    &__item-color {
      width: rem(15px);
      height: rem(3px);
      margin-right: sizer(1.5);

      &.hunters {
        background-color: #1fc5d0;
      }

      &.traders {
        background-color: #188bb4;
      }

      &.owners {
        background-color: #0166ff;
      }
    }

    &__item-value {
      font-size: fs(msmall);
      color: var(--charts-general-text-color);
      font-family: $ff-base;
    }
  }
}
