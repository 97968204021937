@import '../../../assets/scss/utils';

.db-loader {
  &__animation {
    position: absolute;
    width: sizer(25);
    height: sizer(19);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: z(super);
  }

  &__description {
    display: block;
    width: 100%;
    text-align: center;
    font-size: fs(regular);
    color: var(--loader-description-font-color);
    margin-top: sizer(-4.5);
  }
}
