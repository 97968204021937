//to override external components without too much code.

//Kendo grid
.k-grid {
  background-color: transparent !important;
  border: none !important;

  .k-table-thead {
    background-color: transparent !important;

    th {
      color: var(--app-selector-header-font-color);
      font-weight: 700;
      border: 0;
    }
  }

  .k-grid-header {
    background-color: transparent;

    .k-table-thead {
      background-color: transparent !important;

      th {
        color: var(--app-selector-header-font-color);
        font-weight: 700;
      }
    }
  }

  .k-grid-content {
    background-color: transparent;

    .k-table-row {
      background-color: var(--app-selector-body-row-bg);

      &.k-table-alt-row {
        background-color: var(--app-selector-body-row-bg);
      }

      &:hover {
        background-color: var(--app-selector-body-row-bg-hover) !important;
      }
    }
  }

  td {
    height: sizer(4);
    color: var(--app-selector-titles-color);
    border-bottom: 2px solid var(--dashboard-wrapper-bg) !important;
  }
}
