$c-grid-main: #ffffff06;
$c-grid-secondary: #ffffff0f;
$c-white: #fff;
$c-black: #000;
$c-main: #1c1b21;

$grid-text: $c-white;
$grid-header-text: $c-white;
$grid-bg: transparent;
$grid-alt-bg: $c-grid-main;
$grid-hovered-bg: $c-grid-secondary;
$grid-header-bg: $c-grid-main;
$kendo-input-bg: $c-grid-main;
$kendo-input-text: $c-white;
$kendo-button-bg: $c-grid-main;
$kendo-button-text: $c-white;
$kendo-list-text: $c-white;
$kendo-list-border: $c-grid-secondary;
$kendo-list-bg: $c-main;
$kendo-list-item-hover-text: $c-white;
$kendo-list-item-hover-bg: $c-grid-secondary;

@import '@progress/kendo-theme-default/scss/grid/_index';
