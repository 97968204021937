@for $i from 1 through 5 {
    .ml-#{$i},
    .mx-#{$i} {
        margin-left: rem($gutter * $i);
    }

    .mr-#{$i},
    .mx-#{$i} {
        margin-right: rem($gutter * $i);
    }

    .mt-#{$i},
    .my-#{$i} {
        margin-top: rem($gutter * $i);
    }

    .mb-#{$i},
    .my-#{$i} {
        margin-bottom: rem($gutter * $i);
    }

    .pl-#{$i},
    .px-#{$i} {
        padding-left: rem($gutter * $i);
    }

    .pr-#{$i},
    .px-#{$i} {
        padding-right: rem($gutter * $i);
    }

    .pt-#{$i},
    .py-#{$i} {
        padding-top: rem($gutter * $i);
    }

    .pb-#{$i},
    .py-#{$i} {
        padding-bottom: rem($gutter * $i);
    }
}

@each $name, $value in $breakpoints {
    @for $i from 0 through 5 {
        .ml-#{$i}-#{$name},
        .mx-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                margin-left: rem($gutter * $i);
            }
        }

        .mr-#{$i}-#{$name},
        .mx-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                margin-right: rem($gutter * $i);
            }
        }

        .mt-#{$i}-#{$name},
        .my-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                margin-top: rem($gutter * $i);
            }
        }

        .mb-#{$i}-#{$name},
        .my-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                margin-bottom: rem($gutter * $i);
            }
        }

        .pl-#{$i}-#{$name},
        .px-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                padding-left: rem($gutter * $i);
            }
        }

        .pr-#{$i}-#{$name},
        .px-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                padding-right: rem($gutter * $i);
            }
        }

        .pt-#{$i}-#{$name},
        .py-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                padding-top: rem($gutter * $i);
            }
        }

        .pb-#{$i}-#{$name},
        .py-#{$i}-#{$name} {
            @include media-breakpoint(#{$name}) {
                padding-bottom: rem($gutter * $i);
            }
        }
    }
}
