@import '../../../assets/scss/utils';

.dashboard-general-row-values {
  padding: 0 sizer(2);

  &__items-wrapper {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding-left: sizer(1.5);
    border-left: 1px solid var(--dashboard-generalRowValues-item-separator-color);
    padding-bottom: sizer(1);

    &:first-child {
      padding-left: 0;
      border-left: 0;
    }
  }

  &__update {
    font-size: fs(small);
    color: var(--dashboard-generalRowValues-label-color);
    margin-bottom: sizer(1.25);

    > span {
      font-family: $ff-base-bold;
      color: var(--dashboard-generalRowValues-label-date-color);
    }
  }

  &__label {
    font-size: fs(small);
    color: var(--dashboard-generalRowValues-label-color);
    margin-bottom: sizer(0.5);
  }

  &__url {
    font-size: fs(msmall);
    color: var(--dashboard-generalRowValues-url-color);
    text-decoration: none;

    &:visited {
      color: var(--dashboard-generalRowValues-url-color);
      text-decoration: none;
    }
  }

  &__value {
    font-family: $ff-base-bold;
    font-size: fs(xxlarge);
    margin-bottom: sizer(1);
  }
}
