@import '../../assets/scss/utils';

.analytics {
  &__body {
    padding: sizer(3) sizer(7) sizer(7) sizer(7);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: sizer(2);
  }

  &__selector {
    min-width: sizer(42);
    font-size: fs(xxxlarge);
    color: var(--notifications-page-title-color);
  }
}
