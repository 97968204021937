@import '../../../assets/scss/utils';

.db-input-text {
  height: rem(38px);
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid;
  border-bottom-color: var(--input-text-border-bottom-color);
  font-size: fs(regular);
  color: var(--input-text-font-color);
  width: 100%;

  &::placeholder {
    color: var(--input-text-placeholder-font-color);
    font-size: fs(regular);
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--input-text-border-bottom-color-hover);
  }

  &:disabled {
    color: var(--input-text-main-color-disabled);
    border-bottom-color: var(--input-text-main-color-disabled);

    &::placeholder {
      color: var(--input-text-main-color-disabled);
    }
  }

  &:has(~ .db-input-group__error-wrapper .db-input-group__error-container) {
    border-bottom-color: transparent;
    

    &:hover,
    &:focus {
      border-bottom-color: transparent;
    }
  }

  &.with-search {
    padding: 0 sizer(2) 0 sizer(5);
  }
}
