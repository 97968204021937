@import '../../../assets/scss/utils';

.go-back-header {
  display: flex;
  align-items: center;
  margin-bottom: sizer(4);

  .db-icon {
    font-size: fs(large);
    margin-right: sizer(1.25);
    cursor: pointer;
  }

  &__title {
    display: block;
    font-size: fs(xlarge);
    color: var(--app-selector-titles-color);
    font-family: $ff-base;
  }
}
