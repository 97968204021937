@import '../../../assets/scss/utils';

.switch {
  position: relative;
  display: inline-block;
  width: sizer(5.25);
  height: sizer(3);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--app-toggle-rest-bg);
  border: 1px solid var(--app-toggle-rest-border-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: sizer(2);
  width: sizer(2);
  left: 4px;
  bottom: 3px;
  background-color: var(--app-toggle-ball-rest-bg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
  border: 1px solid var(--app-toggle-active-border-color);
}

input:disabled + .slider {
  background-color: var(--c-gray-2);
  cursor: auto;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  border: 1px solid var(--app-toggle-active-border-color);
  background-color: var(--app-toggle-ball-active-bg);
}

/* Rounded sliders */
.slider.round {
  border-radius: 12px;
}

.slider.round:before {
  border-radius: 50%;
}
