@import '../../../assets/scss/utils';

$boxes-container-width: sizer(12);

.box-size {
  &__wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: sizer(3);
    gap: calc((100% - #{$boxes-container-width} * 4) / 3);
  }

  &__container {
    display: grid;
    grid-gap: sizer(1);
    grid-template-columns: repeat(3, 16px);
    grid-auto-rows: minmax(16px, auto);
    width: $boxes-container-width;
    height: sizer(9);
    padding: rem(14);
    border-radius: sizer(0.5);
    background-color: rgba(116, 124, 133, 0.08);
    border: 2px solid transparent;
    cursor: pointer;

    &:hover,
    &.selected {
      border-color: var(--form-section-title-color);
    }

    &.selected {
      cursor: auto;
    }

    &.oneByOne {
      .box-size {
        &__child {
          &:first-child {
            opacity: 1;
          }
        }
      }
    }

    &.oneByTwo {
      .box-size {
        &__child {
          &:first-child,
          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }

    &.twoByOne {
      .box-size {
        &__child {
          &:first-child,
          &:nth-child(4) {
            opacity: 1;
          }
        }
      }
    }

    &.oneByThree {
      .box-size {
        &__child {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            opacity: 1;
          }
        }
      }
    }

    &.twoByThree {
      .box-size {
        &__child {
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:last-child {
            opacity: 1;
          }
        }
      }
    }
    &.twoByTwo {
      .box-size {
        &__child {
          &:first-child,
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(5) {
            opacity: 1;
          }
        }
      }
    }
  }

  &__child {
    width: sizer(2);
    height: sizer(2);
    background-color: #747c85;
    opacity: 0.25;
    border-radius: sizer(0.25);

    &:first-child {
      grid-column: 1;
      grid-row: 1;
    }

    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }

    &:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
    }

    &:nth-child(4) {
      grid-column: 1;
      grid-row: 2;
    }

    &:nth-child(5) {
      grid-column: 2;
      grid-row: 2;
    }

    &:last-child {
      grid-column: 3;
      grid-row: 2;
    }
  }
}
