@import '../../../assets/scss/utils';

.app-selected {
  display: block;
  width: 100%;

  &__row {
    display: flex;
    width: 100%;
    background-color: var(--app-selector-body-row-bg);
    margin-bottom: sizer(4);
  }

  &__cell {
    display: flex;
    height: sizer(9);

    &:first-child {
      display: flex;
      min-width: sizer(30);
      width: sizer(30);
      padding-left: sizer(2);
      align-items: center;

      p {
        display: block;
        align-self: flex-start;
        padding-top: sizer(2);
      }
    }

    &:last-child {
      flex-grow: 1;
      padding-right: sizer(2);

      p {
        text-align: left;
        line-height: rem(22px);
        padding-top: sizer(2);
      }
    }
  }

  &__cell-img-container {
    width: sizer(6);
    height: sizer(6);
    border-radius: sizer(2);
    margin-right: sizer(2);
  }

  &__cell-img-uploaded {
    width: sizer(6);
    height: sizer(6);
    border-radius: sizer(2);
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
  }

  &__cell-img {
    width: sizer(6);
    height: sizer(6);
    border-radius: sizer(2);
    background-image: url('../../../assets/images/app-default.svg');
    background-size: 100% 100%;
  }

  &__cell-text {
    font-size: fs(regular);
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: sizer(4);
    margin-bottom: sizer(4);
  }

  &__info-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: sizer(4);
  }

  &__info {
    display: flex;
    align-items: center;
    height: sizer(5);
    border-radius: sizer(2.5);
    padding: 0 sizer(2);
    background-color: var(--app-selector-body-row-bg);

    .db-icon {
      font-size: fs(xlarge);
      margin-right: sizer(1.25);

      &:hover {
        color: var(--app-selected-info-font-color);
      }
    }

    > p {
      font-size: fs(regular);
      color: var(--app-selected-info-font-color);
    }
  }

  &__info-separator {
    width: 1px;
    height: 100%;
    background-color: var(--app-selected-info-separator-bg);
    margin: 0 sizer(1.5);
  }

  &__info-hide {
    color: var(--app-selected-info-font-color-hide) !important;
    cursor: pointer;
  }

  &__users-list {
    display: flex;
    flex-flow: column;

    &--header {
      display: flex;
      align-items: center;
      padding-bottom: sizer(2);

      .country {
        flex-grow: 1;
        padding-right: sizer(2);
      }
    }

    &--header-cell {
      font-weight: 700;
      color: var(--app-selected-users-list-header-font-color);
      font-size: fs(small);
      flex-grow: 1;
      width: 100%;
      flex-basis: 0px;

      &.size-2 {
        flex-grow: 2;
      }

      &:first-child {
        padding-left: sizer(2);
      }
    }

    &--row {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      background-color: var(--app-selected-users-list-row-bg-color);
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        .app-selected__users-list {
          &--cell {
            background-color: var(--app-selector-body-row-hover-bg);
          }
        }
      }

      &.disabled {
        cursor: auto;
        background-color: var(--app-selected-users-list-row-disabled-bg);
        color: var(--app-selected-users-list-row-disabled-font-color);

        .app-selected__users-list {
          &--cell {
            color: var(--app-selected-users-list-row-disabled-font-color);
          }
        }

        &:hover {
          .app-selected__users-list {
            &--cell {
              background-color: transparent;
            }
          }
        }
      }
    }

    &--cell {
      font-size: fs(regular);
      height: sizer(5);
      line-height: sizer(5);
      color: var(--app-selected-users-list-row-font-color);
      flex-basis: 0px;
      flex-grow: 1;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: sizer(2);

      &.size-2 {
        flex-grow: 2;
      }

      &:first-child {
        padding-left: sizer(2);
      }

      .content {
        display: inline-block;
      }
    }
  }
}
