@import '../../../../assets/scss/utils';

.users-assignment-list {
  padding: 2rem;
  height: calc(100% - 200px);
  &__wrapper {
    overflow: auto;
    height: 100%;
    &--row {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      background-color: var(--app-selected-users-list-row-bg-color);
      cursor: pointer;
      font-size: 0.9375rem;
      min-height: 2.5rem;
      padding: sizer(1) 0 sizer(1) sizer(2);

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: var(--app-selector-body-row-hover-bg);
      }

      &.disabled {
        cursor: auto;
        background-color: var(--app-selected-users-list-row-disabled-bg);
        color: var(--app-selected-users-list-row-disabled-font-color);

        .app-selected__users-list {
          &--cell {
            color: var(--app-selected-users-list-row-disabled-font-color);
          }
        }

        &:hover {
          .app-selected__users-list {
            &--cell {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
