@import '../../../../assets/scss/utils';

.alerts-panel {
  background-color: var(--notification-bg);
  display: flex;
  flex-flow: column;
  height: 100%;
  right: 0;
  position: fixed;
  top: 0;
  width: sizer(75);
  z-index: 3;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sizer(4);

    .db-icon {
      font-size: fs(regular);
      cursor: pointer;
    }
  }

  &__title {
    display: block;
    font-size: fs(xlarge);
    color: var(--notification-panel-title-color);
  }

  &__list {
    width: 100%;
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    min-height: sizer(24.5);
    padding: sizer(3) sizer(4);
    border-bottom: 1px solid;
    border-bottom-color: var(--notification-panel-item-separate-border-color);
    background-color: var(--notification-panel-item-bg);

    &.read {
      background-color: transparent;

      & .alerts-panel__item-title {
        color: var(--notification-panel-item-secondary-text-color);
      }
    }

    &:first-child {
      border-top: 1px solid;
      border-top-color: var(--notification-panel-item-separate-border-color);
    }

    &.alert {
      .notifications-panel__item-title {
        display: block;
        font-size: fs(xlarge);
        color: var(--c-error);
      }

      .alert-icon {
        width: sizer(5);
        min-width: sizer(5);
        height: sizer(5);
        background-repeat: no-repeat;
      }

      &.arca {
        .alert-icon {
          background-image: url('../../../../assets/images/alert-arca.svg');
        }
      }

      &.arena {
        .alert-icon {
          background-image: url('../../../../assets/images/alert-arena.svg');
        }
      }

      &.journey {
        .alert-icon {
          background-image: url('../../../../assets/images/alert-journey.svg');
        }
      }
    }

    &.task {
      .task-icon {
        width: sizer(5);
        min-width: sizer(5);
        height: sizer(5);
        background-repeat: no-repeat;
      }

      &.arca {
        .task-icon {
          background-image: url('../../../../assets/images/task-arca.svg');
        }
      }

      &.arena {
        .task-icon {
          background-image: url('../../../../assets/images/task-arena.svg');
        }
      }

      &.journey {
        .task-icon {
          background-image: url('../../../../assets/images/task-journey.svg');
        }
      }
    }
  }

  &__item-time {
    font-size: fs(msmall);
    white-space: nowrap;
    color: var(--notification-panel-item-secondary-text-color);
  }

  &__item-ball {
    width: sizer(5);
    min-width: sizer(5);
    height: sizer(5);
    line-height: sizer(5);
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    color: var(--c-white);
    font-size: fs(base);

    &.journey {
      background-color: var(--c-journey);
    }

    &.arca {
      background-color: var(--c-arca);
    }

    &.arena {
      background-color: var(--c-arena);
    }

    &.data-campus {
      background-color: var(--c-data-campus);
    }
  }

  &__info {
    flex-grow: 1;
    min-width: 0;
    flex-wrap: wrap;
    padding-left: sizer(2);
  }

  &__item-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-menu {
    display: flex;

    .dropdown-menu-component {
      cursor: pointer;
      margin-left: sizer(1);

      .dropdown-menu__container {
        top: sizer(4);
        left: sizer(-18.5);
      }

      .dropdown-menu__list-item {
        width: sizer(21.25);

        .db-icon {
          font-size: fs(xlarge);
        }
      }
    }
  }

  &__item-title {
    font-size: fs(xlarge);
    color: var(--notification-panel-item-main-text-color);
    margin-bottom: sizer(1);
  }

  &__item-subtitle {
    font-size: fs(regular);
    font-weight: 700;
    color: var(--notification-panel-item-main-text-color);
    margin-bottom: sizer(1);
  }

  &__item-description {
    font-size: fs(msmall);
    color: var(--notification-panel-item-secondary-text-color);
    margin-bottom: sizer(1);
  }

  &__item-detail {
    font-size: fs(regular);
    color: var(--notification-panel-item-detail-text-color);
    text-decoration: none;
  }
}
