$font-url: '../../fonts';
$font-url-iconsFont: '../../fonts/iconsFont';

@font-face {
  font-family: 'Telefonica Web Regular';
  src: url('#{$font-url}/Telefonica_Sans_Regular.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Web Light';
  src: url('#{$font-url}/Telefonica_Sans_Light.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Web Bold';
  src: url('#{$font-url}/Telefonica_Sans_Bold.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Web Extra Bold';
  src: url('#{$font-url}/Telefonica_Sans_Bold.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Web Italic';
  src: url('#{$font-url}/Telefonica_Sans_Italic.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'db icon';
  src: url('#{$font-url-iconsFont}/db-icons-font.woff') format('woff');
  font-style: normal;
}
