@mixin media-breakpoint($name) {
  @if map-has-key($breakpoints, $name) {
    @media (max-width: em(map-get($breakpoints, $name))) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoints}`. "+"Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
