.chart-filters-wrapper {
  display: flex;
  padding-left: sizer(2);
  padding-right: sizer(2);
  margin-bottom: sizer(1);

  .db-input-radio {
    margin-right: sizer(3.5);

    & ~ .db-input-group__error-wrapper {
      display: none;
    }
  }
}

.k-chart {
  width: 100%;
  padding: 0 sizer(2);

  .k-chart-surface {
    height: 100%;
  }

  text {
    fill: var(--charts-general-text-color);
  }
}

.k-chart-tooltip {
  padding: sizer(1);
  background-color: var(--charts-tooltip-background);
  border-radius: 6px;
  font-size: fs(msmall);
  font-family: $ff-base;
  color: var(--charts-tooltip-font-color);

  table {
    thead {
      th {
        font-family: $ff-base-bold;
      }
    }
  }
}
