@import '../../assets/scss/utils';

.permissions-management {
  &__wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    height: 100%;
    padding: sizer(2.5) sizer(7) sizer(7) sizer(7);
    width: 100%;
    background-color: var(--dashboard-wrapper-bg);
  }

  &__title {
    display: block;
    font-size: fs(xxxlarge);
    color: var(--app-selector-titles-color);
    margin-bottom: sizer(6);
  }
}
