@import '../../../assets/scss/utils';

.custom-tooltip {
  min-width: 50px;

  &__title {
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: center;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    &--year {
      margin-right: 10px;
    }
  }
}
