@import '../../../../assets/scss/utils';

.filter-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;

  & .chart-filters-wrapper {
    max-width: fit-content;
    flex-grow: 1;

    & .db-input-select {
      min-width: sizer(20);
    }

    & .radio-group {
      display: flex;
    }
  }
}
