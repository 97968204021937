@import '../../../../assets/scss/utils';

.pie-chart {
  &__wrapper {
    position: relative;
    height: calc(100% - #{rem(20px)});
    width: 100%;

    > div {
      width: 100%;
      height: 100%;

      .highcharts-credits {
        display: none;
      }
    }
  }
}
