@import '../../../assets/scss/utils';

.db-input-select {
  &__control {
    background-color: transparent !important;
    border: 0;
    border-radius: 0 !important;
    border-width: 0 !important;
    border-bottom: 1px solid !important;
    border-bottom-color: var(--input-select-border-bottom-color) !important;
    cursor: pointer !important;

    .db-input-select__single-value {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: fs(regular);
      color: var(--input-select-font-color) !important;
    }

    .db-input-select__indicator {
      color: var(--input-select-indicator-color) !important;
    }

    .db-input-select__multi-value {
      background: var(--input-select-multiple-value-font-bg);
      &__label {
        color: var(--input-select-multiple-value-font-color) !important;
      }
    }

    .db-input-select__input {
      color: var(--input-select-multiple-value-font-color) !important;
    }

    &:hover {
      border-bottom-color: var(--input-select-border-bottom-color-hover) !important;

      .db-input-select__single-value {
        color: var(--input-select-font-color-hover) !important;
      }

      .db-input-select__indicator {
        color: var(--input-select-indicator-color-hover) !important;
      }
    }

    &--is-focused {
      border-bottom-color: var(--input-select-border-bottom-color-focused) !important;
      box-shadow: none !important;

      .db-input-select__single-value {
        color: var(--input-select-font-color-focused) !important;
      }

      .db-input-select__indicator {
        color: var(--input-select-indicator-color-focused) !important;
      }
    }
  }

  &__placeholder {
    font-size: fs(regular);
    color: var(--input-select-placeholder-color) !important;
  }

  &__value-container {
    background-color: transparent;
    padding: 0 !important;
  }

  &__indicator-separator {
    display: none;
  }

  &--is-disabled {
    .db-input-select__control {
      border-bottom-color: var(--input-select-disabled-color) !important;
    }

    .db-input-select__single-value {
      color: var(--input-select-disabled-color) !important;
    }

    .db-input-select__indicator {
      color: var(--input-select-disabled-color) !important;
    }
  }

  &__menu {
    background-color: var(--input-select-menu-bg) !important;
    z-index: z(modal-overlay) !important;
  }

  &__option {
    padding: sizer(1.5) !important;
    background-color: transparent !important;
    cursor: pointer !important;
    color: var(--c-white) !important;

    &:hover {
      background-color: var(--input-select-option-hover-bg) !important;
    }

    &--is-selected {
      background-color: var(--input-select-option-selected-bg) !important;

      &:hover {
        background-color: var(--input-select-option-selected-bg) !important;
      }
    }
  }
}
