html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
main,
menu,
nav,
section,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

img {
    max-width: 100%;
    border-width: 0;
    -ms-interpolation-mode: bicubic;
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol,
ul {
    list-style-type: none;
}

input,
select,
textarea,
button {
    outline: none;
    padding: 0;

    :focus {
        outline: none;
    }
}

input::-ms-clear {
    display: none;
}

textarea {
    resize: none;
}
