@import '../../../../assets/scss/utils';

.worldMap-container {
  position: relative;
  padding-left: sizer(2);
  padding-right: sizer(2);
  width: 100%;
  height: 100%;

  .worldMap {
    &__wrapper {
      background-image: url('../../../../assets/images/worldmap.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: calc(100% - 97px);
    }

    &__info {
      &--title {
        > p {
          font-family: $ff-base-bold;
          font-size: fs(regular);
          color: var(-chart-map-title-color);
        }
      }

      &--description {
        color: var(--chart-map-description-color);
        font-size: fs(msmall);
        margin-top: sizer(0.5);
        margin-bottom: sizer(0.5);
      }

      &--detail {
        color: var(--chart-map-detail-url-color);
        font-size: fs(msmall);
        cursor: pointer;

        &:visited {
          color: var(--chart-map-detail-url-color);
          text-decoration: none;
        }
      }
    }
  }
}
