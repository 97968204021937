@import '../../../assets/scss/utils';

.dashboard-table-risks {
  display: flex;
  flex-flow: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    background-color: transparent !important;

    span {
      font-family: $ff-base-bold;
      color: var(--dashboard-tableReports-header-text-color) !important;
    }
  }

  &__body {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    height: sizer(4);
    background-color: var(--dashboard-tableReports-item-bg);
    margin-bottom: sizer(0.25);

    span {
      display: block;
      font-size: fs(regular);
      color: var(--dashboard-tableReports-item-color);

      &:first-child {
        width: sizer(7);
        padding-left: sizer(2);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__country {
    width: 35%;
    padding-right: sizer(1.5);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__item-column {
    margin-right: sizer(2);
    width: sizer(20);

    &:first-child {
      width: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
