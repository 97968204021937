@import '../../../assets/scss/utils';

.db-side-panel-application {
  margin-bottom: sizer(2);
  &__body {
    padding: sizer(4);
    .folder {
      margin-top: sizer(2);
    }
    .folder:nth-child(1) {
      margin-top: 0;
    }
  }
}
