@import '../../assets/scss/utils';

.shared-layout {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    background-color: var(--shared-layout-container-bg);
    border: 1px solid var(--shared-layout-container-border-color);
    border-radius: 12px;
    box-shadow: 0px 20px 80px 0px #111111bf;
    padding: sizer(8) sizer(6);
    margin-top: sizer(-12.5);

    .db-icon {
      align-self: flex-start;
      font-size: fs(xxxxlarge);
      margin-right: sizer(3.5);
    }
  }

  &__code-error {
    display: block;
    font-size: fs(xxxlarge);
    color: var(--shared-layout-container-text-color);
    margin-bottom: sizer(3);
  }

  &__description {
    display: block;
    font-size: fs(regular);
    color: var(--shared-layout-container-text-color);
    margin-bottom: sizer(2);
  }

  &__navigation {
    text-decoration: none;
    font-size: fs(msmall);
    text-transform: uppercase;
    color: var(--shared-layout-container-text-navigation-color);
    letter-spacing: 1px;
    font-weight: 700;
  }
}
